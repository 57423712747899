import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

export const AboutView = () => {
  return (
    <div className="container mx-auto px-4 max-w-3xl pb-16">
      <Link 
        to="/"
        className="inline-flex items-center gap-2 text-stone-400 hover:text-stone-300 mb-8"
      >
        <ArrowLeft className="w-4 h-4" />
        back to events
      </Link>
      
      <div className="prose prose-invert max-w-none">
        <h1 className="text-4xl font-bold text-stone-100 mb-8">What is POPOUT?</h1>
        
        <div className="space-y-6 text-stone-300">
          <p className="text-lg">
            We showcase a curated selection of popup events hosted by hand-picked venues. 
            Dining at your neighborhood dive, perusing local crafts at a temporary retail installation, open mics with up and coming artists...
          </p>
          <h2 className="text-2xl font-semibold text-stone-100 mt-12">Our Ethos</h2>
          <ul className="space-y-4">
            <li>
              Scroll less, do more. No doomscrolling.
            </li>
            <li>
              Anti-digital-engagement. Find an event, share it, and LOG OFF!
            </li>
          </ul>
          
          <div className="bg-neutral-800 rounded-lg p-8 mt-12 pb-16">
            <h2 className="text-2xl font-semibold text-stone-100 mb-4">Get In Touch</h2>
            <p className="mb-4">
              Have a pop-up event you'd like to share? Want to collaborate? Want POPOUT in your city? We'd love to hear from you.
            </p>
            <a 
              href="https://www.instagram.com/popoutdotnyc" 
              className="text-[#a891f5] hover:text-[#a891f5]/80 transition-colors"
            >
              DM @popoutdotnyc →
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutView;
