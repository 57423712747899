import { Helmet } from 'react-helmet';

export const SEO = ({ title }) => {
  const siteName = 'POPOUT.NYC';
  const fullTitle = title ? `${title} | ${siteName}` : siteName;
  
  return (
    <Helmet>
      <title>{fullTitle}</title>
    </Helmet>
  );
};
