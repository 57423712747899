export const Footer = () => {
  return (
    <footer className="bg-neutral-800 border-t border-neutral-700/50 py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="flex gap-6">
            <a href="https://datagoose.net" className="text-stone-400 hover:text-stone-100 transition-colors">DATAGOOSE LLC</a>
          </div>
        </div>
      </div>
    </footer>
  );
};
