import React from 'react';
import { Share } from 'lucide-react';
import { formatDisplayTime } from '../utils/calendarUtils';
import { format } from 'date-fns';

const ShareButton = ({ event }) => {
  const eventUrl = `${window.location.origin}/e/${event.link_hash}`;

  const getShareText = () => {
    const time = formatDisplayTime(event.start_time);
    const date = format(event.date, 'MMM d');
    return `${event.event_title} - ${date} ${time}`;
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: event.event_title,
          text: getShareText(),
          url: eventUrl
        });
      } catch (error) {
        if (error.name !== 'AbortError') {
          navigator.clipboard.writeText(eventUrl);
        }
      }
    } else {
      navigator.clipboard.writeText(eventUrl);
    }
  };

  return (
    <button
      onClick={handleShare}
      className="inline-flex items-center gap-2 text-[#a891f5] hover:text-[#a891f5]/80 transition-colors"
      aria-label="share event"
    >
      <span className="text-sm">share</span>
      <Share className="w-5 h-5" />
    </button>
  );
};

export default ShareButton;
