import { Link } from 'react-router-dom';

export const Header = () => {
  return (
    <header className="bg-neutral-900 py-8 mb-8">
      <div className="container mx-auto px-4">
        <div className="flex justify-center items-center">
          <div className="w-[44rem] max-w-full flex flex-col items-center">
            <Link 
              to="/about"
            >
              <h1 
                className="text-center font-bold tracking-tight"
                style={{
                  fontSize: "clamp(2.5rem, 15vw, 8rem)",
                  color: "#a891f5",
                  textShadow: `
                    0 0 5px #a891f5,
                    0 0 20px #a891f5
                  `,
                }}
              >
                POPOUT.NYC
              </h1>
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
