import React, { useState } from 'react';
import { X, Filter } from 'lucide-react';
import { getColorClasses } from '../utils/colorConfig';

export const FilterPills = ({ filters, setFilters }) => {
  const [isOpen, setIsOpen] = useState(false);
  
  const eventTypes = ['ALL', 'FOOD', 'CLOTHES', 'ART', 'MUSIC', 'POLITICS', 'EDUCATION', 'MARKET', 'LITERATURE', 'OTHER'];
  const boros = ['ALL', 'BROOKLYN', 'QUEENS', 'MANHATTAN'];

  const handleRemoveFilter = (type, value) => {
    setFilters(prev => ({
      ...prev,
      [type === 'boro' ? 'boro' : 'eventType']: 'ALL'
    }));
  };

  const handleSelectFilter = (type, value) => {
    setFilters(prev => ({
      ...prev,
      [type === 'boro' ? 'boro' : 'eventType']: value
    }));
    setIsOpen(false);
  };

  return (
    <div className="relative">
      <div className="flex flex-wrap items-center gap-2">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="inline-flex items-center gap-2 px-3 py-1.5 rounded-full bg-stone-800 text-stone-300 hover:bg-stone-700 transition-colors text-sm"
        >
          <Filter className="w-4 h-4" />
          <span>Filters</span>
        </button>
        
        {filters.eventType !== 'ALL' && (
          <div className={`inline-flex items-center gap-1.5 px-3 py-1.5 rounded-full text-sm ${getColorClasses(filters.eventType)}`}>
            <span>{filters.eventType}</span>
            <button
              onClick={() => handleRemoveFilter('eventType', filters.eventType)}
              className="hover:opacity-80"
            >
              <X className="w-3 h-3" />
            </button>
          </div>
        )}
        
        {filters.boro !== 'ALL' && (
          <div className={`inline-flex items-center gap-1.5 px-3 py-1.5 rounded-full text-sm ${getColorClasses(filters.boro)}`}>
            <span>{filters.boro}</span>
            <button
              onClick={() => handleRemoveFilter('boro', filters.boro)}
              className="hover:opacity-80"
            >
              <X className="w-3 h-3" />
            </button>
          </div>
        )}
      </div>

      {isOpen && (
        <div className="absolute left-0 top-full mt-2 w-64 bg-neutral-800 rounded-lg shadow-lg border border-neutral-700 p-4 space-y-4 z-10">
          <div>
            <h3 className="text-xs font-medium text-stone-500 mb-2">EVENT TYPE</h3>
            <div className="grid grid-cols-2 gap-2">
              {eventTypes.map(type => (
                <button
                  key={type}
                  onClick={() => handleSelectFilter('eventType', type)}
                  className={`px-3 py-1.5 rounded-full text-sm text-left ${
                    type === filters.eventType
                      ? getColorClasses(type, 'filterSelected')
                      : getColorClasses(type, 'filterUnselected')
                  }`}
                >
                  {type}
                </button>
              ))}
            </div>
          </div>
          
          <div>
            <h3 className="text-xs font-medium text-stone-500 mb-2">BORO</h3>
            <div className="grid grid-cols-2 gap-2">
              {boros.map(boro => (
                <button
                  key={boro}
                  onClick={() => handleSelectFilter('boro', boro)}
                  className={`px-3 py-1.5 rounded-full text-sm text-left ${
                    boro === filters.boro
                      ? getColorClasses(boro, 'filterSelected')
                      : getColorClasses(boro, 'filterUnselected')
                  }`}
                >
                  {boro}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterPills;
