import React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { FilterPills } from './FilterPills';

export const WeekNavigation = ({ weekLabel, onPrevious, onNext, filters, setFilters, eventCount }) => {
  const getEventCountText = () => {
    const plural = eventCount === 1 ? 'event' : 'events';
    return `${eventCount} ${plural}`;
  };

  return (
    <div className="space-y-6 mb-8">
      {/* Filters Row */}
      <div className="flex justify-left">
        <FilterPills filters={filters} setFilters={setFilters} />
      </div>

      {/* Centered Week Navigation */}
      <div className="relative">
        <div className="absolute left-0 top-1/2 -translate-y-1/2">
          <button
            onClick={onPrevious}
            className="p-2 text-stone-500 hover:text-stone-400 transition-colors rounded-full hover:bg-stone-800/50"
            aria-label="Previous week"
          >
            <ChevronLeft size={20} />
          </button>
        </div>

        <h1 className="text-2xl font-light text-stone-500 text-center px-20">
          {getEventCountText()} {weekLabel.toLowerCase()}
        </h1>

        <div className="absolute right-0 top-1/2 -translate-y-1/2">
          <button
            onClick={onNext}
            className="p-2 text-stone-500 hover:text-stone-400 transition-colors rounded-full hover:bg-stone-800/50"
            aria-label="Next week"
          >
            <ChevronRight size={20} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default WeekNavigation;
