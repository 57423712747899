import React from 'react';
import { Calendar, MapPin, Clock } from 'lucide-react';
import { Link } from 'react-router-dom';
import { getColorClasses } from '../utils/colorConfig';
import { createGoogleCalendarUrl, downloadICSFile, formatDisplayTime } from '../utils/calendarUtils';
import ShareButton from './ShareButton';

export const EventCard = ({ event }) => {
  const getGoogleMapsUrl = () => {
    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      `${event.venue_name} ${event.address}`
    )}`;
  };

  const handleAppleCalendarClick = (e) => {
    e.preventDefault();
    downloadICSFile(event);
  };

  const displayTime = () => {
    const start = formatDisplayTime(event.start_time);
    const end = event.end_time ? formatDisplayTime(event.end_time) : '';
    return end ? `${start} - ${end}` : start;
  };

  return (
    <div className="bg-neutral-800 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 p-6 mb-6 border border-neutral-700/50">
      <div className="space-y-4">
        <div className="flex items-start justify-between gap-4">
          <Link to={`/e/${event.link_hash}`} className="text-2xl font-bold text-stone-100 leading-tight">
            {event.event_title}
          </Link>
          <ShareButton event={event} />
        </div>
  
        <div className="flex gap-2">
          <span className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${getColorClasses(event.event_type)}`}>
            {event.event_type}
          </span>
          <span className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium ${getColorClasses(event.boro)}`}>
            {event.boro}
          </span>
        </div>

        {event.items_offered && (
          <p className="text-stone-300 text-sm">
            {event.items_offered}
          </p>
        )}

        <div className="space-y-2 text-sm text-stone-300">
          <div className="flex items-center gap-2">
            <Clock className="w-4 h-4 text-stone-400" />
            <span>{displayTime()}</span>
          </div>
          
          <div className="flex items-center gap-2">
            <MapPin className="w-4 h-4 text-stone-400" />
            <a 
              href={getGoogleMapsUrl()}
              target="_blank"
              rel="noopener noreferrer"
              className="hover:underline hover:text-stone-100"
            >
              <span>{event.venue_name} - {event.address}</span>
            </a>
          </div>
          
          <div className="flex items-center gap-2 pt-4">
            <Calendar className="w-4 h-4 text-stone-400" />
            <span className="text-sm">Add to calendar:</span>
            <div className="space-x-2">
              <button
                onClick={handleAppleCalendarClick}
                className="text-stone-400 hover:text-stone-100 hover:underline font-medium"
              >
                Apple
              </button>
              <span className="text-stone-500">|</span>
              <a
                href={createGoogleCalendarUrl(event)}
                target="_blank"
                rel="noopener noreferrer"
                className="text-stone-400 hover:text-stone-100 hover:underline font-medium"
              >
                Google
              </a>
            </div>
          </div>
        </div>

        <div className="pt-2 border-t border-neutral-700">
          <a 
            href={event.url}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block text-[#a891f5] hover:text-[#a891f5]/80 transition-colors text-sm font-medium hover:underline"
          >
            view flyer →
          </a>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
