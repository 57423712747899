import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Header } from './components/Header';
import { WeekView } from './components/WeekView';
import { EventView } from './components/EventView';
import { Footer } from './components/Footer';
import { logEvent } from 'firebase/analytics';
import { analytics } from './firebase';
import { SEO } from './components/SEO';
import { generateWebsiteSchema } from './utils/schemaUtils';
import { AboutView } from './components/AboutView';

const App = () => {
  useEffect(() => {
    logEvent(analytics, 'page_view');
  }, []);

  return (
    <BrowserRouter>
      <SEO 
        title="NYC Pop-up Events"
        description="Discover unique pop-up events across NYC - from food and art to music and markets. Find the latest happenings in Brooklyn, Queens, and Manhattan."
        canonicalUrl="https://popout.nyc"
        schema={generateWebsiteSchema()}
      />
      <div className="min-h-screen flex flex-col bg-neutral-900">
        <Header />
        <main className="flex-grow container mx-auto px-4">
          <Routes>
            <Route path="/" element={<WeekView />} />
            <Route path="/e/:link_hash" element={<EventView />} />
            <Route path="/about" element={<AboutView />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default App;
